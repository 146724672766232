import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import DefaultLayout from "../../shared/components/DefaultLayout";
import SignupVisual from "../components/SignupVisual";
import { requestSignup } from "../../shared/api/requests";

const Signup = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onFinish = async (values: any) => {
    setLoading(true);
    setErrors(null);
    const res = await requestSignup(values);

    if (!res.isOk) {
      setLoading(false);
      setErrors(res.errors);
    } else
      navigate(`/profil?${searchParams.toString()}`, {
        replace: true,
        state: { mail: values.email },
      });
  };

  const goToSignin = () => {
    navigate(`/signin?${searchParams.toString()}`);
  };

  return (
    <DefaultLayout>
      <SignupVisual
        goToSignin={goToSignin}
        errors={errors}
        isLoading={isLoading}
        onFinish={onFinish}
      />
    </DefaultLayout>
  );
};

export default Signup;
